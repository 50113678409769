import React, { useContext } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
// import useGetLocalProfile from 'hooks/useGetLocalProfile'
import { injected, bsc, walletconnect,CoinbaseWallet } from 'connectors'
import links from './config'

const Menu: React.FC = (props) => {
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const priceData = useGetPriceData()
  
  // console.log("pricedtaa",priceData);
  


  const pantherAddress = '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa'
 
 
  // const profile = useGetLocalProfile()
  // const cakePriceUsd = 0.35;
  // console.log("cakeprcie",cakePriceUsd);
  


  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={(connectorId: ConnectorId) => {
        console.log("Saasasas",connectorId)
        if (connectorId === 'coinbase') {
          return activate(CoinbaseWallet)
        }
        if (connectorId === 'walletconnect') {
          return activate(walletconnect)
        }

        if (connectorId === 'bsc') {
          return activate(bsc)
        }

        return activate(injected)
      }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={Number(priceData.toFixed(3))}
      priceLink="https://www.coingecko.com/en/coins/knightswap"
      /* profile={profile} */
      {...props}
    />
  )
}

export default Menu
