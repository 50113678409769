import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal } from '@pancakeswap-libs/uikit'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
}

// TODO: use UI Kit
const CogIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
     d="M21.9,14.3l-1.1-.6a2,2,0,0,1-.7-2.8,2.3,2.3,0,0,1,.7-.7l1.1-.6a1.9,1.9,0,0,0,.8-2.5V6.9l-1-1.6a1.8,1.8,0,0,0-2.5-.8H19l-1.2.7a1.9,1.9,0,0,1-2.6-.7,1.7,1.7,0,0,1-.3-1V2.3A2,2,0,0,0,13,.3H11a2,2,0,0,0-1.9,2V3.5a1.9,1.9,0,0,1-2,1.9,1.3,1.3,0,0,1-.9-.2L5,4.6a1.9,1.9,0,0,0-2.6.6h0l-1,1.6a2.2,2.2,0,0,0,.7,2.8l1.1.6A1.9,1.9,0,0,1,3.9,13a1.6,1.6,0,0,1-.7.7l-1.1.6a1.9,1.9,0,0,0-.8,2.5c0,.1.1.1.1.2l1,1.6a1.8,1.8,0,0,0,2.5.8H5l1.2-.6a1.9,1.9,0,0,1,2.6.7,1.7,1.7,0,0,1,.3,1v1.3a2,2,0,0,0,1.9,2h2a2,2,0,0,0,1.9-2V20.5a1.9,1.9,0,0,1,2-1.9,1.3,1.3,0,0,1,.9.2l1.2.7a1.9,1.9,0,0,0,2.6-.6h0l1-1.6A2.3,2.3,0,0,0,21.9,14.3ZM12,15.9A3.9,3.9,0,1,1,15.9,12,4,4,0,0,1,12,15.9Z"
      fill="currentColor"
    />
  </svg>
)

const HistoryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5,11.4a6.1,6.1,0,0,0-6.1,6.1,6.1,6.1,0,0,0,6.1,6.2,6.2,6.2,0,0,0,6.2-6.2h0A6.1,6.1,0,0,0,17.5,11.4ZM7.3,18.5a.6.6,0,0,0-.9.1.6.6,0,0,0,0,.9l.3.3H5a3.2,3.2,0,0,1-3.3-3.2v-1A.7.7,0,0,0,1,15a.7.7,0,0,0-.7.6v1A4.7,4.7,0,0,0,5,21.2H6.7l-.3.3a.7.7,0,0,0,0,1,.6.6,0,0,0,.9,0L8.9,21a.6.6,0,0,0,0-.9h0ZM19,2.8H17.3l.3-.3a.7.7,0,0,0,0-1,.6.6,0,0,0-.9,0L15.1,3a.6.6,0,0,0,0,.9h0l1.6,1.5a.6.6,0,0,0,.9-.1.6.6,0,0,0,0-.9l-.3-.3H19a3.2,3.2,0,0,1,3.3,3.2v1a.7.7,0,0,0,1.4,0v-1A4.7,4.7,0,0,0,19,2.8ZM6.5.3A6.2,6.2,0,0,0,.3,6.5a6.1,6.1,0,0,0,6.2,6.1,6.1,6.1,0,0,0,6.1-6.1h0A6.1,6.1,0,0,0,6.5.3Z"
      fill="currentColor"
    />
  </svg>
)

const StyledPageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 24px;
`

const Details = styled.div`
  flex: 1;
`

const PageHeader = ({ title, description, children }: PageHeaderProps) => {
  const [onPresentSettings] = useModal(<SettingsModal />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal />)

  return (
    <StyledPageHeader className='card-m'>
      <Flex className='cont-icon-wrp' alignItems="center">
       <div className='hp-cont'>
       <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <Text color="#ff720d" fontSize="14px">
              {description}
            </Text>
          )}
        </Details>
       </div>
        <div className='st-icon'>
        <IconButton className='setting' variant="text" onClick={onPresentSettings} title="Settings">
          <CogIcon />
        </IconButton>
        <IconButton className='timer' variant="text" onClick={onPresentRecentTransactions} title="Recent transactions">
          <HistoryIcon />
        </IconButton>
        </div>
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
